.box_paymentScreenLoader{
  display: flex;
  align-items: center;
  justify-content: center;
}
.container_paymentScreenLoader {
    height: 15px;
    width: 105px;
    display: flex;
    position: relative;
  }
  .container_paymentScreenLoader .circle_paymentScreenLoader {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #000;
    animation: move 500ms linear 0ms infinite;
    margin-right: 30px;
  }
  .container_paymentScreenLoader .circle_paymentScreenLoader:first-child {
    position: absolute;
    top: 0;
    left: 0;
    animation: grow 500ms linear 0ms infinite;
  }
  .container_paymentScreenLoader .circle_paymentScreenLoader:last-child {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 0;
    animation: grow 500ms linear 0s infinite reverse;
  }
  
  @keyframes grow {
    from {
      transform: scale(0, 0);
      opacity: 0;
    }
    to {
      transform: scale(1, 1);
      opacity: 1;
    }
  }
  @keyframes move {
    from {
      transform: translateX(0px);
    }
    to {
      transform: translateX(45px);
    }
  }