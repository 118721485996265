html {
  overscroll-behavior-x: none;
  cursor: none !important;
}
body {
  overscroll-behavior-x: none;
}
button{
  cursor: none !important;
}
